import * as React from "react";
import Layout from '../components/layout';
import Container from '../components/container';
import {StaticImage} from "gatsby-plugin-image";

class PolitykaPrywatnosciPage extends React.Component {
    render () {
        return (
            <Layout>
                <section className="relative pt-[4.8125rem] md:pt-[6.8125rem]">
                    <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
                    <Container>
                        <div className="">
                            <h1 className="text-white text-3xl font-semibold py-8 md:text-6xl md:py-12 lg:text-7xl lg:py-16">Polityka prywatności</h1>
                        </div>
                    </Container>
                </section>

                <section>
                    <Container>
                        <div className="prose py-12 md:py-16 max-w-none">
                            <h2>I. Informacje ogólne</h2>

                            <ol>
                                <li>Higher Sp. z o.o. z siedzibą przy ul. Lwowskiej 5/15, 00-079 Warszawa (dalej: Higher lub Administrator) stosownie do przepisów art. 173-174 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne, informuje o wykorzystywaniu w serwisie dostępnym pod adresem https://changeshapers.pl (dalej: Serwis) plików cookies i innych podobnych technologii internetowych (takich jak Web Storage).</li>
                                <li>Za pomocą plików cookies oraz innych technologii internetowych zbierane są jedynie anonimowe dane statystyczne o użytkownikach. Informacje uzyskiwane dzięki wskazanym technologiom nie są przypisywane do konkretnej osoby i nie pozwalają jej zidentyfikować.</li>
                                <li>Umieszczanie i wykorzystywanie plików cookies oraz innych technologii internetowych nie jest szkodliwe dla urządzenia użytkownika (tj. komputera, telefonu lub tabletu), nie powoduje żadnych zmian w konfiguracji urządzenia, zainstalowanym oprogramowaniu i aplikacjach.</li>
                                <li>
                                    Celem wykorzystywania przez Serwis powyżej wymienionych technologii jest:
                                    <ul>
                                        <li>dostosowanie zawartości stron internetowych Serwisu do preferencji użytkownika oraz optymalizacja korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie użytkownika i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb, zapewniają bezpieczne korzystanie z Serwisu i jego podstawowych funkcji;</li>
                                        <li>tworzenie anonimowych analiz, raportów i statystyk dotyczących sposobu korzystania ze stron Serwisu przez użytkowników, co umożliwia ulepszanie ich struktury i zawartości;</li>
                                        <li>prezentacja reklam, w tym w oparciu o preferencje użytkownika.</li>
                                    </ul>
                                </li>
                            </ol>

                            <h2>II. Pliki cookies i inne technologie</h2>

                            <ol>
                                <li>
                                    W czasie korzystania z Serwisu na urządzeniu użytkownika umieszczane są małe pliki tekstowe. Standardowo w tych plikach znajdują się następujące informacje:
                                    <ul>
                                        <li>nazwa serwisu, z którego plik cookie został wysłany;</li>
                                        <li>wygenerowany unikatowy numer;</li>
                                        <li>czas przechowywania pliku.</li>
                                    </ul>
                                </li>
                                <li>
                                    Pliki te można podzielić ze względu na źródło pochodzenia oraz na czas ich przetrzymywania na urządzeniu użytkownika. W Serwisie wykorzystywane są następujące typy plików:
                                    <ul>
                                        <li>
                                            Ze względu na źródło pochodzenia:
                                            <ul>
                                                <li>first-party cookies – pochodzące z Serwisu; w pliku zapisywana jest nazwa wskazująca na domenę Administratora (tj. changeshapers.pl);</li>
                                                <li>third-party cookies – umieszczane za pośrednictwem Serwisu przez podmioty zewnętrzne, z których usług korzysta Administrator (np. Google Ireland Limited, Facebook Ireland Limited, LinkedIn Ireland Unlimited Company).</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Ze względu na czas przez jaki pliki cookies będą umieszczone na urządzeniu użytkownika:
                                            <ul>
                                                <li>pliki cookies sesyjne (session cookies), które tworzone są każdorazowo po wejściu na stronę Serwisu oraz usuwane w momencie zamknięcia okna przeglądarki;</li>
                                                <li>pliki cookies stałe (persistent cookies) przechowywane w urządzeniu końcowym użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez osobę odwiedzającą Serwis.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Pliki firm zewnętrznych umieszczane są na urządzeniu użytkownika w szczególności:
                                    <ul>
                                        <li>w celu wyświetlania dopasowanych materiałów w sieciach reklamowych;</li>
                                        <li>do badania aktywności anonimowych użytkowników i na tej podstawie generowania statystyk (np. za pomocą narzędzi Hotjar i Google Analytics), które pomagają w poznaniu sposobów korzystania z Serwisu;</li>
                                        <li>w celu umożliwienia korzystania z umieszczonych w Serwisie usług i technologii podmiotów zewnętrznych (np. filmów YouTube, map Google);</li>
                                        <li>w celu realizacji własnych celów marketingowych Higher.</li>
                                    </ul>
                                </li>
                                <li>
                                    Administrator korzysta również z możliwości przeglądarek internetowych, które w swojej pamięci mogą przechowywać informacje, w tym z poniższych technologii umożliwiających przechowywanie danych:
                                    <ul>
                                        <li>IndexedDB – dane przechowywane w postaci obiektów, do których dostęp jest ograniczony wyłącznie dla odpowiednich źródeł danych – domen lub subdomen, z których zostały zapisane;</li>
                                        <li>Session Storage – magazyn danych będący odpowiednikiem cookies, ale o znacznie większej pojemności danych. Dane zgromadzone w magazynie Session Storage są usuwane po zamknięciu okna przeglądarki;</li>
                                        <li>Local Storage – magazyn danych do którego zapisywane są informacje, które są przechowywane w sposób trwały w przeglądarce internetowej użytkownika do czasu ich usunięcia.</li>
                                    </ul>
                                </li>
                            </ol>

                            <h2>III. Podmioty zewnętrzne</h2>

                            <ol>
                                <li>Administrator informuje, że pliki cookies mogą być także zamieszczane i wykorzystywane przez podmioty zewnętrzne. W Serwisie znajdują się osadzone przyciski, narzędzia lub treści kierujące do usług i stron internetowych usługodawców, partnerów oraz innych podmiotów współpracujących z Administratorem. Korzystanie z tych aplikacji może powodować przesyłanie za pomocą plików cookies i podobnych technologii informacji do wskazanych podmiotów zewnętrznych. Administrator zaleca, aby po przejściu na inne strony, użytkownik zapoznał się z polityką cookies wskazanych podmiotów zewnętrznych.</li>
                            </ol>

                            <h2>IV. Jak postępować z plikami cookies i innymi technologiami?</h2>

                            <ol>
                                <li>Użytkownik Serwisu może dokonywać wyborów dotyczących wykorzystania plików cookies przez Higher i podmioty trzecie (w szczególności plików analitycznych i reklamowych). Zmiana ustawień plików cookies może wpłynąć na pogorszenie jakości korzystania z usług lub uniemożliwić korzystanie z niektórych usług i funkcjonalności Serwisu.</li>
                                <li>Użytkownik może zarządzać swoimi preferencjami i w każdej chwili wycofać zgodę na zapisywanie i wykorzystywanie określonych plików cookies (np. do celów analitycznych, badawczych, marketingowych) poprzez zmianę ustawień za pomocą narzędzi dostępnych Serwisie („Ustawienia plików cookies”), co nie ma wpływu na legalność procesu przetwarzania danych sprzed wycofania zgody.</li>
                                <li>Użytkownik może także w dowolnym momencie w swojej przeglądarce internetowej zmienić ustawienia plików cookies lub wyłączyć ich obsługę. Jeżeli użytkownik nie dokona zmiany domyślnych ustawień przeglądarki w zakresie dotyczącym plików zbierających dane, pliki te zostaną umieszczone na urządzeniu końcowym i będą wykorzystywane zgodnie z zasadami określonymi przez dostawcę przeglądarki internetowej.</li>
                                <li>
                                    Informacje na temat zarządzania plikami cookies w poszczególnych przeglądarkach internetowych – w tym w szczególności instrukcje zablokowania odbierania plików cookies – można znaleźć na stronach dedykowanych poszczególnym przeglądarkom:
                                    <ul>
                                        <li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=pl">https://support.google.com/chrome/answer/95647?hl=pl</a></li>
                                        <li>Firefox: <a href="https://support.mozilla.org/pl/kb/ciasteczka">https://support.mozilla.org/pl/kb/ciasteczka</a></li>
                                        <li>Internet Explorer: <a href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a></li>
                                        <li>Microsoft Edge: <a href="https://support.microsoft.com/pl-pl/windows/program-microsoft-edge-i-przegl%C4%85danie-danych-a-prywatno%C5%9B%C4%87-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">https://support.microsoft.com/pl-pl/windows/program-microsoft-edge-i-przegl%C4%85danie-danych-a-prywatno%C5%9B%C4%87-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd</a></li>
                                        <li>Opera: <a href="https://help.opera.com/pl/latest/web-preferences/#cookies">https://help.opera.com/pl/latest/web-preferences/#cookies</a></li>
                                        <li>Safari: <a href="https://support.apple.com/pl-pl/HT201265">https://support.apple.com/pl-pl/HT201265</a></li>
                                    </ul>
                                </li>
                                <li>Użytkownicy, którzy po zapoznaniu się z informacjami dostępnymi w Serwisie nie chcą, aby pliki cookies oraz inne technologie pozostały zachowane w przeglądarce internetowej urządzenia, powinni usunąć je ze swojej przeglądarki po zakończeniu wizyty w Serwisie.</li>
                            </ol>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default PolitykaPrywatnosciPage;